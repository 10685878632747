import React, { FC, useState } from 'react';
import styles from './UserSignature.module.scss';
import { Box, Button, Stack, Typography } from '@mui/material';
import { Stage, Layer, Line, Text } from 'react-konva';
import { set } from 'react-hook-form';

interface UserSignatureProps {

  onSubmit: () => void,
  handleNext(): void,
  handleBack(): void,
  activeStep: number,
  steps: string[],
}

const UserSignature: FC<UserSignatureProps> = ({ onSubmit, handleNext, handleBack, activeStep, steps }) => {
  const [signatured, setSignatured] = useState(false)
  const [error, setError] = useState('');
  const [tool, setTool] = React.useState('pen');
  const [lines, setLines] = React.useState<any[]>([]);
  const isDrawing = React.useRef(false);
  const stageRef = React.useRef<any>({});

  const handleMouseDown = (e: any) => {
    setSignatured(true);
    isDrawing.current = true;
    const pos = e.target.getStage().getPointerPosition();
    setLines([...lines, { tool, points: [pos.x, pos.y] }]);
  };

  const handleMouseMove = (e: any) => {
    // no drawing - skipping
    if (!isDrawing.current) {
      return;
    }
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    let lastLine = lines[lines.length - 1];
    // add point
    lastLine.points = lastLine.points.concat([point.x, point.y]);

    // replace last
    lines.splice(lines.length - 1, 1, lastLine);
    setLines(lines.concat());
  }

  const handleMouseUp = () => {
    isDrawing.current = false;
  };
  
  const exportImage = () => {
    localStorage.setItem('userSignature', JSON.stringify({userSignature: stageRef.current.toDataURL()}));
  };
  const signaturedCheck = () => {
    if (signatured === true) {
      handleNext();
      onSubmit();
    }else{
      setError("Podpis jest wymagany")
    }
  }

  return (
    <>
      <Stack minWidth={'80%'}>
        <Stack marginBottom={3}>
          <Typography variant="h4">Podpis Użytkownika</Typography>
          {error && <p style={{ color: 'red' }}>{error}</p>} 
        </Stack>
        <Stack>

        </Stack>
        <Stack marginBottom={3} >
          <Stage
            ref={stageRef}
            style={{ border: '1px solid #000' }}
            width={400}
            height={200}
            onMouseDown={handleMouseDown}
            onMousemove={handleMouseMove}
            onMouseup={handleMouseUp}
          >
            <Layer>
              {lines.map((line, i) => (
                <Line
                  key={i}
                  points={line.points}
                  stroke="#000"
                  strokeWidth={5}
                  tension={0.5}
                  lineCap="round"
                  lineJoin="round"
                  globalCompositeOperation={
                    line.tool === 'eraser' ? 'destination-out' : 'source-over'
                  }
                />
              ))}
            </Layer>
          </Stage>
        </Stack>

        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Button
            variant="contained"
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Button
            variant="text"
            onClick={() => setLines([])}
            sx={{ mr: 1 }}
          >Resetuj podpis</Button>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button
            variant="contained" onClick={() => {
              exportImage();
              signaturedCheck();
            }}>
            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
          </Button>
        </Box>
      </Stack>
    </>

  );
};

export default UserSignature;
