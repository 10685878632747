import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import ProTip from './ProTip';
import ClaimStepperForm from './components/ClaimStepperForm/ClaimStepperForm';
import { Route, Routes } from 'react-router-dom';
import { AppBar, Button, IconButton, Stack, Toolbar } from '@mui/material';
import CalculateRecompensate from './components/CalculateRecompensate/CalculateRecompensate';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import HomePage from './components/HomePage/HomePage';
import NavBar from './components/NavBar/NavBar';
import InformativePage from './components/InformativePage/InformativePage';
import termsOfUseMarkdown from '../src/data/termsOfUse.md';

function Copyright() {
  return (
    <Typography
      variant="body2"
      align="center"
      sx={{
        color: 'text.secondary',
      }}
    >
      {'Copyright © '}

      {new Date().getFullYear()}.
    </Typography>
  );
}

console.log("QPA" + navigator.language)
export default function App() {


  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={navigator.language}>

        <NavBar />

          <Stack sx={{ my: 12 }} alignItems='center'>
            <Routes>
              <Route path="/" element={<HomePage />}>
              </Route>
              <Route path="/claim" element={<ClaimStepperForm />}>
              </Route>
              <Route path="/calculate" element={<CalculateRecompensate />}>
              </Route>
              <Route path="/terms" element={<InformativePage title="Regulamin" filename={'terms_of_use.md'} />}>
              </Route>
              <Route path="/privacy" element={<InformativePage title="Polityka Prywatności" filename={'privacy_policy.md'} />}>
              </Route>
            </Routes>
            <ProTip />
            <Copyright />
          </Stack>
      </LocalizationProvider >
    </>

  );
}
