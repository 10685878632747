import React, { FC } from 'react';
import styles from './DelayDetails.module.scss';
import { SelectChangeEvent, Box, InputLabel, Select, MenuItem, Stack, Button } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

interface IncidentDetailsProps {
  onSubmit: (data: any) => void
  handleNext(): void,
  handleBack(): void,
  activeStep: number,
  steps: string[],
}

const IncidentDetails: FC<IncidentDetailsProps> = ({ handleNext, handleBack, steps, activeStep }) => {
  const [delay, setDelay] = React.useState('0');

  const { control } = useForm({
    defaultValues: {
      incidentType: 0,
    }
  });

  const handleChange = (event: SelectChangeEvent) => {
    setDelay(event.target.value);
  };

  const options = [
    { value: 0, label: 'Opóźnienie' },
    { value: 1, label: 'Lot odwołany' },
  ];

  return (
    <Stack minWidth={300} maxWidth='80%'>
      <Stack marginBottom={3}>
        <InputLabel id="demo-simple-select-label">Rodzaj zdarzenia</InputLabel>
        <Controller
          name='incidentType'
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={value}
              label="Opóźnienie"
              onChange={onChange}
              fullWidth
              placeholder="Opóźnienie"
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </Stack>

      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Button
          variant="contained"
          color="inherit"
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1 }}
        >
          Back
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button variant="contained" onClick={handleNext}>
          {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
        </Button>
      </Box>
    </Stack>
  );
};

export default IncidentDetails;
