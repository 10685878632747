import React, { FC } from 'react';
import styles from './HomePage.module.scss';
import { Card, Container, Stack, Typography } from '@mui/material';
import PeopleStanding from '../../data/images/people_waiting.png';
import { isMobile } from 'react-device-detect';
import CalculateRecompensate from '../CalculateRecompensate/CalculateRecompensate';
import CardContent from '@mui/material/CardContent';
interface HomePageProps { }

const HomePage: FC<HomePageProps> = () => (
  <>
    <Container>
      <Stack>
        <Stack direction={isMobile ? 'column' : 'row'} spacing={2} marginBottom={20}>
          <Stack>
            <Typography variant="h2" marginBottom={2}>Opóźniony lot?</Typography>
            <Typography variant="h5" marginBottom={10}>Uzyskaj odszkodowanie za opóźniony lot. Podaj potrzebne dane, a my zajmiemy się resztą</Typography>
            <Stack>
              <CalculateRecompensate showTitle={false}></CalculateRecompensate>
            </Stack>
          </Stack>
          <Stack sx={{ maxWidth: isMobile ? '100%' : '50%' }}>
            <img style={{ maxWidth: '100%', borderRadius: '3%' }} src={PeopleStanding}></img>
          </Stack>
        </Stack>

      </Stack>
    </Container>
    <Stack direction='row' spacing={15}>
      <Stack width='30%'>
        <Stack>
          <Typography variant="h4">Sprawdź jakie odszkodowanie Ci się należy</Typography>
        </Stack>
      </Stack>
      <Stack width='30%'>
        <Stack>
          <Typography variant="h4">Podaj nam potrzebne dane</Typography>
        </Stack>
      </Stack>
      <Stack width='30%'>
        <Stack>
          <Typography variant="h4">Odbierz rekompenstate</Typography>
        </Stack>
      </Stack>
    </Stack>

    <Stack direction='row' spacing={15}>
      <Card style={{ width: '28%', textAlign: 'center' }} >
        <CardContent>
          <Typography variant="h4" marginBottom={3}>Sprawdź jakie odszkodowanie Ci się należy</Typography>
          <Typography variant="body1">Podaj lotnisko wylotu i przylotu, aby bezpłatnie obliczyć przysługującą kwotę rekompensaty.</Typography>
        </CardContent>
      </Card>
      <Card style={{ width: '28%', textAlign: 'center' }}>
        <CardContent>
          <Typography variant="h4">Podaj nam potrzebne dane</Typography>
        </CardContent>
      </Card>
      <Card style={{ width: '28%', textAlign: 'center' }}>
        <CardContent>
          <Typography variant="h4">Odbierz rekompenstate</Typography>
        </CardContent>
      </Card>
    </Stack>
  </>
);

export default HomePage;
