import React, { FC, useRef, useState } from 'react';
import styles from './CalculateRecompensate.module.scss';
import { Stack, InputLabel, Autocomplete, TextField, Button, Typography, InputAdornment } from '@mui/material';
import axios from 'axios';
import { FlightLand, FlightTakeoff } from '@mui/icons-material';
import { useNavigate } from 'react-router';

interface CalculateRecompensateProps {
  showTitle?: boolean;
}

const CalculateRecompensate: FC<CalculateRecompensateProps> = ({ showTitle = true }) => {
  const [, setInputValue] = React.useState('');
  const [options, setOptions] = useState([]);
  const [departure, setDeparture] = useState('');
  const [arrival, setArrival] = useState('');

  const previousController = useRef();
  const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    const url = `${process.env.REACT_APP_BACKEND_URL}/Airport/${event.target.value}`;

    axios
      .get(url)
      .then(function (response: any) {
        // handle success
        const { status, data } = response;
        if (status === 200) {
          console.log(data);
          setOptions(data);
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };


  const redirectToCalculation = () => {
    navigate(`/claim?departure=${departure}&arrival=${arrival}`);
  }

  return (
    <Stack sx={{ width: '80%' }} alignItems="center">
      {showTitle && <Typography variant="h4" component="h1" sx={{ mb: 2 }}>
        Oblicz rekompensatę jaka Ci przysługuje
      </Typography>}
      <Stack style={{ marginBottom: 20 }}>
        <InputLabel id="demo-simple-select-label">Wylot</InputLabel>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={options}
          sx={{ width: 300 }}
          getOptionLabel={(option: any) => `${option.airport_name} (${option.iata_code})`}
          onChange={(e: any, value: any) => setDeparture(value ? value.iata_code : null)}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              fullWidth
              // slotProps={{
              //   input: {
              //     startAdornment: (
              //       <InputAdornment position="start">
              //         <FlightTakeoff />
              //       </InputAdornment>
              //     ),
              //   },
              // }}
              onChange={handleChange}
            />
          )}
        />
      </Stack>


      <Stack style={{ marginBottom: 20 }}>
        <InputLabel id="demo-simple-select-label">Przylot</InputLabel>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={options}
          fullWidth
          sx={{ width: 300 }}
          getOptionLabel={(option: any) => `${option.airport_name} (${option.iata_code})`}
          onChange={(e: any, value: any) => setArrival(value ? value.iata_code : null)}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              fullWidth
              // slotProps={{
              //   input: {
              //     startAdornment: (
              //       <InputAdornment position="start">
              //         <FlightLand  />
              //       </InputAdornment>
              //     ),
              //   },
              // }}
              onChange={handleChange}
            />
          )}
        />
      </Stack>


      <Button variant="contained" onClick={() => redirectToCalculation()} onSubmit={redirectToCalculation}>  Oblicz</Button>


    </Stack>
  );
}

export default CalculateRecompensate;
