import React, { FC, useEffect, useRef, useState } from 'react';
import styles from '../FlightInformationPart/FlightInformationPart.module.scss';
import { Box, Autocomplete, TextField, Grid, InputLabel, Stack, Checkbox, FormControlLabel, Alert, FormControl, Select, Button, InputAdornment } from '@mui/material';
import axios from 'axios'; // Import the 'axios' library
import { Label, Check, AccountCircle, FlightTakeoff, FlightLand } from '@mui/icons-material';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import dayjs, { Dayjs } from 'dayjs';
import { isMobile } from 'react-device-detect';



interface Dictionary<T> {
  [Key: string]: T;
}

interface FlightInformationPartProps {
  onSubmit: (data: any) => void,
  handleNext(): void,
  handleBack(): void,
  activeStep: number,
  departure: string,
  arrival: string,
  steps: string[],
}

interface FlightInformation {
  departure: string;
  arrival: string;
  flightNumber: string;
  euCountry: boolean;
  plannedArrival: Dayjs | null;
  actualArrival: Dayjs | null;
}



const FlightInformationPart: FC<FlightInformationPartProps> = ({ handleNext, handleBack, activeStep, steps, departure, arrival }) => {
  const [compensation, setCompensation] = React.useState(null);
  const [differenceCalculated, setDifferenceCalculated] = React.useState(0);
  const [arrivalOptions, setArrivalOptions] = useState<Dictionary<any[]>>({});
  const [departureOptions, setDepartureOptions] = useState<Dictionary<any[]>>({});
  const [isDataPrefilled, setIsDataPrefilled] = useState(false);


  const { control, trigger, getValues, formState: { errors }, watch } = useForm<FlightInformation>({
    defaultValues: {
      departure: departure || '',
      arrival: arrival || '',
      flightNumber: '',
      euCountry: false,
      plannedArrival: null,
      actualArrival: null,
    }

  });
  const watchTimes = watch(["plannedArrival", "actualArrival"]);
  const watchFlights = watch(["departure", "arrival"]);
  const watchRequiredForRecompensation = watch(["departure", "arrival", "euCountry", "plannedArrival", "actualArrival",]);


  useEffect(() => {
    console.log(departure, arrival);
    if (!departure && !arrival) { // TODO: fix this condition
      setIsDataPrefilled(true);
    }
  }, [])


  useEffect(() => {
    if (watchRequiredForRecompensation[0] && watchRequiredForRecompensation[1] && watchRequiredForRecompensation[3] && watchRequiredForRecompensation[4]) {

      const url = `${process.env.REACT_APP_BACKEND_URL}/Calculate/compensation?airportCodeFrom=${watchRequiredForRecompensation[0]}&airportCodeTo=${watchRequiredForRecompensation[1]}&euCountry=${watchRequiredForRecompensation[2]}&plannedArrivalDate=${watchRequiredForRecompensation[3].format('YYYY-MM-DD HH:mm')}&actualArrivalDate=${watchRequiredForRecompensation[4].format('YYYY-MM-DD HH:mm')}`;

      axios
        .get(url)
        .then(function (response: any) {
          const { status, data } = response;
          if (status === 200) {
            setCompensation(data);
            console.log(data);
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    }
  }, [watchRequiredForRecompensation])

  useEffect(() => {
    if (watchTimes[1] && watchTimes[0] && watchTimes[1] > watchTimes[0]) { // TODO: fix this condition
      setDifferenceCalculated(watchTimes[1].diff(watchTimes[0], 'minutes'));
    }
  }, [watchTimes])

  const handleValidation = async () => {
    const isValid = await trigger(["flightNumber", "plannedArrival", "actualArrival"])
    if (isValid) {
      const values = getValues()
      console.log(values)
      onSubmit();
      handleNext();
    } else {
      console.log("aaaa, chcialoby sie: ", errors)
    }
  }

  useEffect(() => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/Airport/iata/${arrival}`;
    axios
      .get(url)
      .then(function (response: any) {
        const { status, data } = response;
        if (status === 200) {
          setArrivalOptions({ ...arrivalOptions, 'arrival': data });
        }
        setIsDataPrefilled(true);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => setIsDataPrefilled(true));


  }, [arrival]);


  useEffect(() => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/Airport/iata/${departure}`;
    axios
      .get(url)
      .then(function (response: any) {
        const { status, data } = response;
        if (status === 200) {
          setDepartureOptions({ ...arrivalOptions, 'departure': data });
        }
        setIsDataPrefilled(true);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => setIsDataPrefilled(true));

  }, [departure]);


  const onSubmit = () => {
    localStorage.setItem('flightData', JSON.stringify(getValues()));
  };

  const formatDelayTimeInPolish = (delayInMinutes: number): string => {
    const days = Math.floor(delayInMinutes / (24 * 60));
    const hours = Math.floor((delayInMinutes % (24 * 60)) / 60);
    const minutes = delayInMinutes % 60;

    let result = '';

    if (days > 0) {
      result += `${days} ${days === 1 ? 'dzień' : 'dni'}`;
    }

    if (hours > 0) {
      if (result) result += ', ';
      result += `${hours} ${hours === 1 ? 'godzina' : (hours < 5 ? 'godziny' : 'godzin')}`;
    }

    if (minutes > 0) {
      if (result) result += ', ';
      result += `${minutes} ${minutes === 1 ? 'minuta' : (minutes < 5 ? 'minuty' : 'minut')}`;
    }

    return result || '0 minut';
  }

  const handleAutocompleteInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, name: string) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/Airport/${event.target.value}`;
    axios
      .get(url)
      .then(function (response: any) {
        const { status, data } = response;
        if (status === 200) {
          console.log(data);
          switch (name) {
            case 'departure':
              setDepartureOptions({ ...departureOptions, [name]: data });
              break;
            case 'arrival':
              setArrivalOptions({ ...arrivalOptions, [name]: data });
              break;
          }
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  return (
    <Stack width={'80%'}>
      {isDataPrefilled &&
        <>
          <Stack marginBottom={3}>
            <InputLabel id="demo-simple-select-label">Wylot (wpisz aby wyszukać lotnisko)</InputLabel>
            <Controller
              name='departure'
              control={control}
              render={({ field: { onChange, value, name } }) => {
                return (
                  <Autocomplete
                    id="departure"
                    options={departureOptions[name] ?? []}
                    getOptionLabel={(option: any) => `${option.airport_name} (${option.iata_code})`}
                    value={departureOptions[name]?.find((option: any) => value ? value === option.iata_code : departure === option.iata_code)}
                    onChange={(e: any, value: any) => onChange(value ? value.iata_code : null)}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        // slotProps={{
                        //   input: {
                        //     startAdornment: (
                        //       <InputAdornment position="start">
                        //         <FlightTakeoff />
                        //       </InputAdornment>
                        //     ),
                        //   },
                        // }}
                        onChange={e => handleAutocompleteInputChange(e, name)}
                      />
                    )}
                  />
                );
              }}
            />
          </Stack>

          <Stack marginBottom={3}>
            <InputLabel id="demo-simple-select-label">Przylot (wpisz aby wyszukać lotnisko)</InputLabel>
            <Controller
              name='arrival'
              control={control}
              defaultValue={arrival}
              render={({ field: { onChange, value, name } }) => {
                return (
                  <Autocomplete
                    id="arrival"
                    options={arrivalOptions[name] ?? []}
                    getOptionLabel={(option: any) => `${option.airport_name} (${option.iata_code})`}
                    value={arrivalOptions[name]?.find((option: any) => value ? value === option.iata_code : arrival === option.iata_code)}
                    onChange={(e: any, value: any) => onChange(value ? value.iata_code : null)}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        // slotProps={{
                        //   input: {
                        //     startAdornment: (
                        //       <InputAdornment position="start">
                        //         <FlightLand />
                        //       </InputAdornment>
                        //     ),
                        //   },
                        // }}
                        onChange={e => handleAutocompleteInputChange(e, name)}
                      />
                    )}
                  />
                );
              }}
            />
          </Stack>
        </>}

      <Stack marginBottom={3}>
        <InputLabel id="demo-simple-select-label">Podaj numer lotu</InputLabel>
        <Controller
          name='flightNumber'
          rules={{
            required: "Numer lotu jest wymagany",
            minLength: { value: 2, message: "Numer lotu jest za krótki" },
            maxLength: { value: 8, message: "Numer lotu jest za długi" }
          }}
          control={control}
          render={({ field: { onChange, value, name } }) => {
            return (
              <TextField
                variant="outlined"
                value={value}
                onChange={onChange}
                error={!!errors.flightNumber}
                helperText={errors.flightNumber ? errors.flightNumber.message : ""}
              />
            );
          }}
        />
      </Stack>

      <Stack marginBottom={3}>
        <FormControlLabel
          control={<Controller
            name='euCountry'
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Checkbox
                  checked={value}
                  onChange={onChange}
                />
              );
            }}
          />} label="Podróż miała początek lub koniec w kraju należącym do UE" />
      </Stack>

      <Stack marginBottom={3}>
        <InputLabel id="demo-simple-select-label">Data i czas planowanego przylotu do celu</InputLabel>
        <Controller
          name='plannedArrival'
          control={control}
          rules={{
            required: "Data planowanego przylotu jest wymagana",
          }}
          render={({ field: { onChange, value } }) => (
            <DateTimePicker value={value} onChange={onChange} slotProps={{
              textField: {
                error: !!errors.plannedArrival,
                helperText: errors.plannedArrival ? errors.plannedArrival.message : ""
              }
            }} />
          )}
        />
      </Stack>

      <Stack marginBottom={3}>
        <InputLabel id="demo-simple-select-label">Data i czas rzeczywistego przylotu do celu</InputLabel>
        <Controller
          name='actualArrival'
          control={control}
          rules={{
            required: "Data rzeczywistego przylotu jest wymagana",
          }}
          render={({ field: { onChange, value } }) => (
            <DateTimePicker value={value != dayjs(0) ? value : null} onChange={onChange} slotProps={{
              textField: {
                error: !!errors.actualArrival,
                helperText: errors.actualArrival ? errors.actualArrival.message : ""
              }
            }} />
          )}
        />
      </Stack>
      {differenceCalculated != 0 && (<Stack style={{ marginBottom: 20 }}>
        <Alert icon={<Check fontSize="inherit" />} severity="success">
          Przylot opóźniony o {formatDelayTimeInPolish(differenceCalculated)}
        </Alert>
      </Stack>)}



      {compensation != null && compensation != 0 && (<Stack style={{ marginBottom: 20 }}>
        <Alert icon={<Check fontSize="inherit" />} severity="success">
          Przysługuje Ci {compensation}€ rekompensaty
        </Alert>
      </Stack>)}

      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Button
          variant="contained"
          color="inherit"
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1 }}
        >
          Back
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button type='submit'
          variant="contained" onClick={() => {
            handleValidation()
          }}>
          {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
        </Button>
      </Box>
    </Stack>

  );
};


export default FlightInformationPart;
