import React, { FC, useEffect, useState } from 'react';
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { Stack, Typography, Divider, Container } from '@mui/material';
import termsOfUse from '../../data/termsOfUse.md';
import privacyPolicy from '../../data/termsOfUse.md';

interface InformativePageProps {
  title: string;
  filename: string;
}

const InformativePage: FC<InformativePageProps> = ({ title, filename }) => {
  const [markdown, setMarkdown] = useState<string>('');

  useEffect(() => {
    import(`../../data/informativePage/${filename}`).then(res => {
      fetch(res.default).then(res => res.text())
        .then(res => setMarkdown(res))
        .catch(err => console.log(err))
      console.log(res);
    })
  })

  return (
    <Container>
      <Stack>
        <Typography variant="h2">{title}</Typography>
        <Divider />
        <Markdown >{markdown}</Markdown>
      </Stack>
    </Container>

  )
};

export default InformativePage;
