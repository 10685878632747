import React, { FC } from 'react';
import styles from './NavBar.module.scss';
import { AppBar, Toolbar, IconButton, Typography, Button } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../data/images/claimflight_icon.png'
import DropdownMenu from '../DropdownMenu/DropdownMenu';

interface NavBarProps { }

const NavBar: FC<NavBarProps> = () => {

  const navigate = useNavigate();

  const policiesDropdownMenuItems = [
    { label: "Regulamin", onClick: () => navigate('/terms')},
    { label: "Polityka prywatności", onClick: () => navigate('/privacy') }
  ];

  return (
    <AppBar position="sticky" sx={{ bgcolor: "white" }}>
      <Toolbar>
        {!isMobile && <>
          <IconButton
            size="large"
            edge="start"
            color="secondary"
            aria-label="menu"
            sx={{ mr: 2, color: "black" }}
          >
          </IconButton>
          <Typography onClick={() => navigate("/")} style={{}}><img style={{ objectFit: 'contain' }} src={logo} alt='Logo' width='250px' height='80px' /></Typography>

          <DropdownMenu label="Polityki" items={policiesDropdownMenuItems} />
          <Button variant="text" onClick={() => navigate("/claim")}>Formularz zgłoszeniony</Button>
          <Button variant="text" onClick={() => navigate("/calculate")}>Sprawdz rekomensatę</Button>
        </>
        }

        {isMobile && <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>}
      </Toolbar>
    </AppBar>
  );

}

export default NavBar;
